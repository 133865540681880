import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import { handleError } from 'api/api-utils'
import dimensionsApi from 'api/DimensionsApi'

import {
  createDimensionValueError,
  createDimensionValueSuccess,
  deleteDimensionValueError,
  deleteDimensionValueSuccess,
  getDimensionSuccess,
  getDimensionError,
  updateDimensionError,
  updateDimensionSuccess,
  transferBalancesSuccess,
  transferBalancesError,
} from './actions'
import {
  GET_DIMENSION,
  CREATE_DIMENSION_VALUE,
  DELETE_DIMENSION_VALUE,
  UPDATE_DIMENSION,
  TRANSFER_BALANCES,
} from './constants'

// When updating a Dimension, we don't want to change just any property
const UpdateDimensionRecord = Record({
  name: undefined,
  code: undefined,
  displayName: undefined,
  dimensionType: undefined,
  parentId: undefined,
  hideFromBudgeting: undefined,
  hideFromReporting: undefined,
  useGeneratedDisplayName: undefined,
})

export function* updateDimension(action) {
  const { companyCode, dimension, updatedDimension } = action

  const patch = Diff(
    new UpdateDimensionRecord(dimension),
    new UpdateDimensionRecord(updatedDimension)
  ).toJS()

  try {
    const updatedApiDimension = yield call(
      dimensionsApi.patchDimension,
      companyCode,
      dimension.id,
      patch
    )
    yield put(
      updateDimensionSuccess({ companyCode, dimension: updatedApiDimension })
    )
  } catch (error) {
    yield put(handleError(error, updateDimensionError))
  }
}

export function* createDimensionValue(action) {
  const {
    companyCode,
    code,
    displayName,
    name,
    dimensionId,
    parentId,
    hideFromBudgeting,
    hideFromReporting,
  } = action

  try {
    const dimensionValue = yield call(
      dimensionsApi.createDimensionValue,
      companyCode,
      {
        code,
        displayName,
        name,
        dimensionId,
        parentId,
        hideFromBudgeting,
        hideFromReporting,
      }
    )
    yield put(createDimensionValueSuccess({ companyCode, dimensionValue }))
  } catch (error) {
    yield put(handleError(error, createDimensionValueError))
  }
}

export function* deleteDimensionValue(action) {
  const { companyCode, dimensionValueId } = action

  try {
    yield call(
      dimensionsApi.deleteDimensionValue,
      companyCode,
      dimensionValueId
    )
    yield put(deleteDimensionValueSuccess({ companyCode, dimensionValueId }))
  } catch (error) {
    yield put(handleError(error, deleteDimensionValueError))
  }
}

export function* getDimension(action) {
  const { companyCode, dimensionId } = action

  try {
    const dimension = yield call(
      dimensionsApi.getDimension,
      companyCode,
      dimensionId
    )
    yield put(getDimensionSuccess({ dimension }))
  } catch (error) {
    yield put(handleError(error, getDimensionError))
  }
}

export function* transferBalances(action) {
  const { companyCode, sourceDimensionValueId, targetDimensionValueId } = action

  try {
    yield call(
      dimensionsApi.transferBalances,
      companyCode,
      sourceDimensionValueId,
      targetDimensionValueId
    )
    yield put(transferBalancesSuccess())
  } catch (error) {
    yield put(handleError(error, transferBalancesError))
  }
}

export function* dimensionSaga() {
  yield all([
    takeLatest(GET_DIMENSION, getDimension),
    takeEvery(DELETE_DIMENSION_VALUE, deleteDimensionValue),
    takeEvery(CREATE_DIMENSION_VALUE, createDimensionValue),
    takeEvery(UPDATE_DIMENSION, updateDimension),
    takeEvery(TRANSFER_BALANCES, transferBalances),
  ])
}

export default dimensionSaga
