/*
 *
 * Dimension actions
 *
 */

import {
  CREATE_DIMENSION_VALUE,
  CREATE_DIMENSION_VALUE_ERROR,
  CREATE_DIMENSION_VALUE_SUCCESS,
  DELETE_DIMENSION_VALUE,
  DELETE_DIMENSION_VALUE_ERROR,
  DELETE_DIMENSION_VALUE_SUCCESS,
  GET_DIMENSION,
  GET_DIMENSION_ERROR,
  GET_DIMENSION_SUCCESS,
  UPDATE_DIMENSION,
  UPDATE_DIMENSION_ERROR,
  UPDATE_DIMENSION_SUCCESS,
  TOGGLE_SHOW_DIMENSION_VALUE_FORM,
  TRANSFER_BALANCES,
  TRANSFER_BALANCES_SUCCESS,
  TRANSFER_BALANCES_ERROR,
} from './constants'

export const transferBalances = ({ companyCode, ...form }) => ({
  type: TRANSFER_BALANCES,
  companyCode,
  ...form,
})

export const transferBalancesSuccess = () => ({
  type: TRANSFER_BALANCES_SUCCESS,
})

export const transferBalancesError = (error) => ({
  type: TRANSFER_BALANCES_ERROR,
  error,
})

export const getDimension = ({ companyCode, dimensionId }) => ({
  type: GET_DIMENSION,
  companyCode,
  dimensionId,
})

export const getDimensionError = (error) => ({
  type: GET_DIMENSION_ERROR,
  error,
})

export const getDimensionSuccess = ({ dimension }) => ({
  type: GET_DIMENSION_SUCCESS,
  dimension,
})

export const createDimensionValue = ({
  companyCode,
  code,
  displayName,
  name,
  dimensionId,
  parentId,
  hideFromBudgeting,
  hideFromReporting,
}) => ({
  type: CREATE_DIMENSION_VALUE,
  companyCode,
  code,
  displayName,
  name,
  dimensionId,
  parentId,
  hideFromBudgeting,
  hideFromReporting,
})

export const createDimensionValueSuccess = ({
  companyCode,
  dimensionValue,
}) => ({
  type: CREATE_DIMENSION_VALUE_SUCCESS,
  companyCode,
  dimensionValue,
})

export const createDimensionValueError = (error) => ({
  type: CREATE_DIMENSION_VALUE_ERROR,
  error,
})

export const deleteDimensionValue = ({
  companyCode,
  dimensionValueId,
  dimensionId,
}) => ({
  type: DELETE_DIMENSION_VALUE,
  companyCode,
  dimensionValueId,
  dimensionId,
})

export const deleteDimensionValueSuccess = ({
  companyCode,
  dimensionValueId,
}) => ({
  type: DELETE_DIMENSION_VALUE_SUCCESS,
  companyCode,
  dimensionValueId,
})

export const deleteDimensionValueError = (error) => ({
  type: DELETE_DIMENSION_VALUE_ERROR,
  error,
})

export const updateDimension = ({
  companyCode,
  dimension,
  updatedDimension,
}) => ({
  type: UPDATE_DIMENSION,
  companyCode,
  dimension,
  updatedDimension,
})

export const updateDimensionError = (error) => ({
  type: UPDATE_DIMENSION_ERROR,
  error,
})

export const updateDimensionSuccess = ({ companyCode, dimension }) => ({
  type: UPDATE_DIMENSION_SUCCESS,
  companyCode,
  dimension,
})

export const toggleShowDimensionValueForm = (state) => ({
  type: TOGGLE_SHOW_DIMENSION_VALUE_FORM,
  state,
})
