/*
 *
 * Dimension reducer
 *
 */

import { fromJS } from 'immutable'

import { GET_DIMENSIONS_SUCCESS } from 'containers/Dimensions/constants'

import {
  CREATE_DIMENSION_VALUE,
  CREATE_DIMENSION_VALUE_ERROR,
  DELETE_DIMENSION_VALUE,
  DELETE_DIMENSION_VALUE_ERROR,
  GET_DIMENSION,
  GET_DIMENSION_ERROR,
  UPDATE_DIMENSION,
  UPDATE_DIMENSION_ERROR,
  TOGGLE_SHOW_DIMENSION_VALUE_FORM,
  TRANSFER_BALANCES,
  TRANSFER_BALANCES_SUCCESS,
  TRANSFER_BALANCES_ERROR,
} from './constants'

const initialState = fromJS({
  loading: false,
  error: false,
  showDimensionValueForm: false,
})

function dimensionReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DIMENSION:
    case TRANSFER_BALANCES:
    case CREATE_DIMENSION_VALUE:
    case DELETE_DIMENSION_VALUE:
    case GET_DIMENSION:
      return state.set('loading', true).set('error', false)

    case TOGGLE_SHOW_DIMENSION_VALUE_FORM:
      return state.set('showDimensionValueForm', action.state)

    case UPDATE_DIMENSION_ERROR:
    case CREATE_DIMENSION_VALUE_ERROR:
    case DELETE_DIMENSION_VALUE_ERROR:
    case TRANSFER_BALANCES_ERROR:
    case GET_DIMENSION_ERROR:
      return state.set('loading', false).set('error', action.error)

    // After updating/deleting/creating dimension or dimensionvalue,
    // dimensions are fetched again
    case TRANSFER_BALANCES_SUCCESS:
    case GET_DIMENSIONS_SUCCESS:
      return state.set('loading', false)

    default:
      return state
  }
}

export default dimensionReducer
